export default [
  {
    title: 'Dashboard',
    icon: '',
    route: 'dashboard',
    minAccess: 1,
    maxAccess: 1001,
    except: 0,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Instansi',
    icon: '',
    route: 'pengajuan-instansi',
    minAccess: 1,
    maxAccess: 1001,
    except: 100,
    exceptA: 101,
    exceptB: 2026,
  },
  {
    title: 'Pengajuan',
    icon: '',
    route: 'pengajuan',
    minAccess: 99,
    maxAccess: 110,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Verifikasi Teknis',
    icon: '',
    route: 'verifikasi-teknis',
    minAccess: 69,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Rekomendasi Awal',
    icon: '',
    route: 'rekomendasi-awal',
    minAccess: 69,
    maxAccess: 80,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Persetujuan Eselon 2',
    icon: '',
    route: 'persetujuan-direktur',
    minAccess: 49,
    maxAccess: 60,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Rekapitulasi Eselon 2',
    icon: '',
    route: 'rekapitulasi',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Pembahasan Dispute',
    icon: '',
    route: 'pembahasan-dispute',
    minAccess: 49,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2023,
  },
  {
    title: 'Konfirmasi Rekomendasi',
    icon: '',
    route: 'konfirmasi-rekomendasi',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Berita Acara Konfirmasi',
    icon: '',
    route: 'berita-acara-konfirmasi',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Persetujuan Eselon 1',
    icon: '',
    route: 'persetujuan-dirjen',
    minAccess: 39,
    maxAccess: 49,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Rekapitulasi Eselon 1',
    icon: '',
    route: 'rekapitulasi-dirjen',
    minAccess: 39,
    maxAccess: 49,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Penerbitan Surat',
    icon: '',
    route: 'penerbitan-surat',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Surat Rekomendasi',
    icon: '',
    route: 'surat-rekomendasi',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Antrian',
    icon: '',
    route: 'daftar-antrian',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Efisiensi',
    icon: '',
    route: 'daftar-efisiensi',
    minAccess: 39,
    maxAccess: 71,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    minAccess: 0,
    maxAccess: 21,
    except: 1000,
    exceptA: 0,
    exceptB: 2026,
    children: [
      {
        title: 'Arsitektur SPBE',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Proses Bisnis',
            route: 'master-domain-proses-bisnis',
          },
          {
            title: 'Layanan',
            route: 'master-domain-layanan',
          },
          {
            title: 'Data dan Informasi',
            route: 'master-domain-data-informasi',
          },
          {
            title: 'Aplikasi',
            route: 'master-domain-aplikasi',
          },
          {
            title: 'Infrastruktur',
            route: 'master-domain-infrastruktur',
          },
          {
            title: 'Keamanan',
            route: 'master-domain-keamanan',
          },
        ],
      },
      {
        title: 'Rekomendasi',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Proses Bisnis',
            route: 'master-template-bisnis',
          },
          {
            title: 'Dokumen',
            route: 'master-template-dokumen',
          },
          {
            title: 'Layanan',
            route: 'master-template-layanan',
          },
          {
            title: 'Data dan Informasi',
            route: 'master-template-data-informasi',
          },
          {
            title: 'Aplikasi',
            route: 'master-template-aplikasi',
          },
          {
            title: 'Infrastruktur',
            route: 'master-template-infrastruktur',
          },
          {
            title: 'Keamanan',
            route: 'master-template-keamanan',
          },
          {
            title: 'Non Teknis',
            route: 'master-template-non-teknis',
          },
        ],
      },
      {
        title: 'Klasifikasi',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Kriteria Belanja',
            route: 'master-group-pengadaan',
          },
          {
            title: 'Jenis Belanja',
            route: 'master-group-belanja',
          },
          {
            title: 'Sumber Dana',
            route: 'master-group-sumber-dana',
          },
          {
            title: 'Status Kegiatan',
            route: 'master-status-kegiatan',
          },
        ],
      },
      {
        title: 'Users Manager',
        icon: 'UsersIcon',
        children: [
          {
            title: 'Users',
            route: 'master-user',
          },
          // {
          //   title: 'Accounts',
          //   route: 'master-account',
          // },
          {
            title: 'Roles',
            route: 'master-roles',
          },
        ],
      },
      {
        title: 'Reports',
        icon: 'FileTextIcon',
        route: 'pages-reports',
      },
      {
        title: 'BSrE Log',
        icon: 'ShieldIcon',
        route: 'log-sistem',
      },
    ],
  },
]
